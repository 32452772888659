@import "variables.scss";

@mixin mobile() {
    @media screen and (max-width: calc($breakpoint-tablet - 0.5px)) {
        @content
    }
}

@mixin tablet() {
    @media screen and (max-width: calc($breakpoint-desktop - 0.5px)) {
        @content
    }
}