
.hidden {
    display: none !important;
}

.clickable {
    cursor: pointer;
}

@mixin scrollable-x {
    overflow: auto;
        
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.scrollable-x {
    @include scrollable-x
}

.scrollable-x-mobile {
    @media screen and (max-width: 768px) {
        @include scrollable-x
    }
}

.shadow-on-hover {
    position: relative;

    &:hover::after {
        opacity: 1;
    }

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.2s ease;
        border-radius: 8px;
    }
}