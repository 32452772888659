:root {
  --color-background: #001424;
  --color-secondary-background: #122840;
  --color-skeleton: linear-gradient(215.49deg, rgba(65, 81, 101, 0.7) -11.6%, rgba(110, 119, 130, 0.7) 31.3%, rgba(65, 81, 101, 0.7) 89.05%);

  --color-white: #fff;
  --color-white-100: #F2F3F7;
  --color-secondary-white: #BCC3D04D;

  --color-secondary-grey: #849AB2;
  --color-icon-grey: #969FA8;


  --color-black: #1D2023;
  --color-black-100: #001424;

  --color-blue: #007cff;
  --color-secondary-blue: #122840;

  --color-gold: #BA9D5A;
  --color-gold-hover: #C8A85F;
}