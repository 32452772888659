@import "/src/styles/mixins.scss";
@import "/src/styles/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // height: 100%;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: var(--header-height);
    height: var(--header-height);
    background-color: var(--color-background);
    z-index: 4;

    %logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: inherit;
      opacity: 1;
    }

    %blocksAroundSearchBar {
      transition: width 0.3s ease-in, opacity 0.6s ease, flex 0.5s ease-out;
      flex: 1;
    }

    .logo {
      @extend %logo;
      @extend %blocksAroundSearchBar;
      flex: 0 0 100px;
    }

    .logoWhenSearch {
      @extend %logo;
      @extend %blocksAroundSearchBar;
      cursor: pointer;

      @media screen and (max-width: 1009px) {
        width: 0;
        opacity: 0;
        overflow: hidden;
      }
    }

    .equalEmptySpace {
      @extend %blocksAroundSearchBar;
    }

    .equalEmptySpaceBeforeOpen {
      transition: flex 0.2s ease;
      flex: 0;
      margin: 0;
      width: 0;
    }

    .searchSection {
      transition: opacity 0.5s ease;
      height: inherit;
      width: 768px;
      display: flex;
      align-items: center;
      opacity: 1;

      @include mobile {
        width: 100%;
      }

      .backButton {
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
        min-width: 40px;
        cursor: pointer;
  
        @include mobile {
          min-width: 43px;
        }
      }

      .searchBar {
        width: inherit;
        margin-right: 40px;
  
        @include mobile {
          margin-right: 43px;
        }
      }
    }

    .searchSectionBeforeOpen {
      transition: opacity 0.5s ease;
      width: 0;
      opacity: 0;
      overflow: hidden;
    }
  }

  .headerFixed {
    @extend .header;
    position: fixed;
  }

  .headerMobileHidden {
    @include mobile {
      display: none;
    }
  }

  .dropDown {
    max-width: 100%;
    z-index: 3;
  }

  .dropDownFixed {
    @extend .dropDown;
    height: calc(100vh - $headerHeight);
    height: calc(var(--1dvh, 1vh) * 100 - $headerHeight);
    height: calc(100dvh - $headerHeight);
    position: absolute;
    top: $headerHeight;
  }

  .content {
    padding-top: 17.5vh;
    display: grid;
    grid-gap: 2.5rem;
    position: relative;
    z-index: 2;
    max-width: 592px;
    margin: 0 25px;
    opacity: 1;
    transition: opacity 0.3s ease;
    transition-delay: 0.4s;

    .inner {
      display: grid;
      grid-gap: 12px;
      justify-items: center;
      text-align: center;

      @include mobile {
        padding: 0 16px;
      }

      h1 {
        font-family: 'MTS Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;

        @include mobile {
          font-size: 20px;
          line-height: 24px;
        }
      }

      p {
        font-family: 'MTS Compact';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;

        @include mobile {
          font-family: 'MTS Text';
          font-size: 17px;
          line-height: 24px;
        }
      }
    }
  }

  .hiddenContent {
    @extend .content;
    opacity: 0;
    transition: opacity 0.15s ease;
    transition-delay: 0;
  }

  .posters {
    display: flex;
    justify-content: center;
    max-width: 100%;
    //overflow: hidden;
  }
}