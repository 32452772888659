@import "/src/styles/mixins.scss";

.container {
  z-index: 1;
  font-family: 'MTS Compact';
  font-style: normal;
  font-weight: 400;

  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 52px 28px;
  
  flex: 0 0 auto;

  @include tablet {
    font-size: 12px;
    line-height: 16px;
    padding: 64px 60px;
  }

  @include mobile {
    padding: 16px;
  }
}