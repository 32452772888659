@import "mixins.scss";

$gutter: 0px;

.container {
  display: grid;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding-left: $gutter;
  padding-right: $gutter;
}

// .row {
//   margin-left: -$gutter;
//   margin-right: -$gutter;

//   & > div {
//     margin-left: $gutter;
//     margin-right: $gutter;
//   }
// }