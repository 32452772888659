.container {
  display: flex;
  align-items: center;

  & .control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: all 200ms linear;
    border: 1px solid transparent;
    transform: scale(0.9);

    &:disabled {
      opacity: 0;
      transform: scale(0.9);
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.05);
      transform: scale(1.1);
    }
  }
}