@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($path+".eot");
    src:
      url($path+'.eot?#iefix') format('embedded-opentype'),
      url($path+'.woff2') format('woff2'),
      url($path+'.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
  }
}

@include font-face("MTS Compact", "../assets/fonts/Compact/MTSCompact-Black/MTSCompact-Black", 900, normal);
@include font-face("MTS Compact", "../assets/fonts/Compact/MTSCompact-Bold/MTSCompact-Bold", 700, normal);
@include font-face("MTS Compact", "../assets/fonts/Compact/MTSCompact-Medium/MTSCompact-Medium", 500, normal);
@include font-face("MTS Compact", "../assets/fonts/Compact/MTSCompact-Regular/MTSCompact-Regular", 400, normal);

@include font-face("MTS Extended", "../assets/fonts/Extended/MTSExtended-Black/MTSExtended-Black", 900, normal);
@include font-face("MTS Extended", "../assets/fonts/Extended/MTSExtended-Bold/MTSExtended-Bold", 700, normal);
@include font-face("MTS Extended", "../assets/fonts/Extended/MTSExtended-Medium/MTSExtended-Medium", 500, normal);
@include font-face("MTS Extended", "../assets/fonts/Extended/MTSExtended-Regular/MTSExtended-Regular", 400, normal);

@include font-face("MTS Text", "../assets/fonts/Text/MTSText-Black/MTSText-Black", 900, normal);
@include font-face("MTS Text", "../assets/fonts/Text/MTSText-Bold/MTSText-Bold", 700, normal);
@include font-face("MTS Text", "../assets/fonts/Text/MTSText-Medium/MTSText-Medium", 500, normal);
@include font-face("MTS Text", "../assets/fonts/Text/MTSText-Regular/MTSText-Regular", 400, normal);

@include font-face("MTS Ultra Extended", "../assets/fonts/UltraExtended/MTSUltraExtended-Black/MTSUltraExtended-Black", 900, normal);
@include font-face("MTS Ultra Extended", "../assets/fonts/UltraExtended/MTSUltraExtended-Bold/MTSUltraExtended-Bold", 700, normal);
@include font-face("MTS Ultra Extended", "../assets/fonts/UltraExtended/MTSUltraExtended-Regular/MTSUltraExtended-Regular", 400, normal);
@include font-face("MTS Ultra Extended", "../assets/fonts/UltraExtended/MTSUltraExtended-Light/MTSUltraExtended-Light", 300, normal);

@include font-face("MTS Wide", "../assets/fonts/Wide/MTSWide-Black/MTSWide-Black", 900, normal);
@include font-face("MTS Wide", "../assets/fonts/Wide/MTSWide-Bold/MTSWide-Bold", 700, normal);
@include font-face("MTS Wide", "../assets/fonts/Wide/MTSWide-Medium/MTSWide-Medium", 500, normal);
@include font-face("MTS Wide", "../assets/fonts/Wide/MTSWide-Regular/MTSWide-Regular", 400, normal);
@include font-face("MTS Wide", "../assets/fonts/Wide/MTSWide-Light/MTSWide-Light", 300, normal);

@mixin font-faceOTF($font-name, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($path+".otf");
    font-weight: $weight;
    font-style: $style;
  }
}

@include font-faceOTF("MTS Sans", "../assets/fonts/Sans/MTSSans-Black", 900, normal);
@include font-faceOTF("MTS Sans", "../assets/fonts/Sans/MTSSans-Bold", 700, normal);
@include font-faceOTF("MTS Sans", "../assets/fonts/Sans/MTSSans-Medium", 500, normal);
@include font-faceOTF("MTS Sans", "../assets/fonts/Sans/MTSSans-Regular", 400, normal);